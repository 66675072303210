import React, {
	Component
} from 'react';
import { Button, Icon } from 'antd';
import "./exception-404.css"

class Exception404 extends Component {

	backHome() {
        this.props.history.replace("/");
    }

	render() {

		return (
			<div className={"wrapper"}>
				<div className={"exception-hint"}>
				<Icon type="frown" style={{ fontSize: '48px' }} />
				<br /><br />
				出错啦
				</div>
				<div className={"exception-btn"}>
				<Button icon="home" size="large" shape="round" type="primary" onClick={() => this.backHome()}>返回首页</Button>
				</div>
			</div>
			
		)
	}
}

export default Exception404;