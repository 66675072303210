import React, {
  Component
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Index from './pages/index/index';
import ProjectPoster from './pages/project/project-poster';
import CollectionPoster from './pages/project/collection-poster';
import CompanyProject from './pages/project/company-project';
import Company127 from './pages/project/company-127';
import Card from './pages/project/card';
import Exception404 from './pages/exception-404/exception-404';
import Pro from "./pages/campaign/pro";
import Home from "./pages/home/index";
import OAuth from "./pages/oauth/redirect";
import './app.css';

class App extends Component {

  render() {
      return (
              <Router>
                  <Switch>
                      <Route exact path="/" component={ Index } />
                      <Route exact path="/contacts-card/:contactsId/:token/:mainColor/:secondColor/:source1st/:sourceFrom" component={ Card } />
                      <Route exact path="/project-poster/:projectId/:mode/:nickName/:avatarUrl/:comment/:source1st/:sourceFrom/:cover" component={ ProjectPoster } />
                      <Route exact path="/collection-poster/:collectionId/:mode/:cover" component={ CollectionPoster } />
                      <Route exact path="/company-127/project/:projectId/:mode/:comment/:source1st/:sourceFrom/:cover" component={ Company127 } />
                      <Route exact path="/company/:companyId/project/:projectId/:nickName/:avatarUrl/:comment/:source1st/:sourceFrom/:cover" component={ CompanyProject } />
                      <Route exact path="/pro" component={ Pro } />
                      <Route exact path="/home" component={ Home } />
                      <Route exact path="/oauth/call-back" component={ OAuth } />
                      <Route path="" component={ Exception404 } />
                  </Switch>
              </Router>
      );
  }
}

export default App;