import React, { Component } from 'react';
import { Anchor, Collapse, Carousel } from 'antd';
import DocumentTitle from 'react-document-title';
import "./campaign.css"

const { Link } = Anchor;
const { Panel } = Collapse;

class Pro extends Component {

  state = {

  }

  componentDidMount() {

  }


  render() {
    return (
      <DocumentTitle title="小程序就用园也Pro">
        <div className="wrapper mobile-wrapper">

          <div className="block">
            <div className="large-title">
              建筑景观行业最专业的小程序<br />
                  就用园也<span className="pro">Pro</span> </div>
          </div>

          <div className="center-title">
            不仅是微信上的官网<br />更为企业赋能
              <span className="subtitle">解放生产力，提升客户价值</span>
          </div>
          <Carousel autoplay>
            <div className="testimony">
              <h3>原来给客户攒这样的PPT要起码一周，现在只需要半天就够了。</h3>
              <h5>—— 王经理，市场部，奥雅设计</h5>
            </div>
            <div className="testimony">
              <h3>终于有一个手机端渠道，可以全面了解山水的项目了，这两年山水的文旅项目更新也愈发频繁了。</h3>
              <h5>—— 马先生，客户，山水比德</h5>
            </div>
            <div className="testimony">
              <h3>减少了公司品牌部门一定的工作量，比如像新设计作品或建成项目图册这种需要及时更新的东西就不用频繁排版了。</h3>
              <h5>—— 徐经理，品牌部，元有景观</h5>
            </div>
            <div className="testimony">
              <h3>园也Pro的后台有完善的项目管理功能，趁小程序上线这个契机，正好将公司的项目好好的汇总整理到一起。</h3>
              <h5>—— 赵经理，品牌部，本色营造</h5>
            </div>
            <div className="testimony">
              <h3>在客户现场，我们直接用iPad连上投影或电视来展示小程序。</h3>
              <h5>—— 彭经理，市场部，元有景观</h5>
            </div>
            <div className="testimony">
              <h3>我们在校招的时候也直接给同学们扫码看小程序，可以看到比公众号丰富得多的内容，同学们也更乐于接受这种新事物。</h3>
              <h5>—— 林经理，人事部，奥雅设计</h5>
            </div>
          </Carousel>

          <div className="center-title black-bg">
            彰显企业独特魅力
            </div>
          <div className="action-img action-center-bottom">
            <Anchor affix={false}>
              <Link href="#apply-pro" title='申请体验' className="action-btn" />
            </Anchor>
            <img alt="设计" src="/images/assets-design.jpg" className="block-img" />
          </div>

          <div className="center-title black-bg">
            多维度检索项目
            </div>
          <div className="action-img action-center-bottom">
            <img alt="项目" src="/images/assets-project.jpg" className="block-img" />
          </div>


          <div className="center-title">
            人手一张的智能名片
              <span className="subtitle">商务拓展的全新标配，构建私域流量</span>
            <span className="subtitle">个性化自主设计，全面展示企业形象</span>
          </div>
          <div className="action-img">
            <img alt="名片" src="/images/assets-card.jpg" className="block-img" />
          </div>

          <div className="center-title grey-bg">
            业内独家的项目地图
              <span className="subtitle">按省、市自动汇总项目</span>
          </div>
          <div className="action-img action-right-top">
            <Anchor affix={false}>
              <Link href="#apply-pro" title='申请体验' className="action-btn" />
            </Anchor>
            <img alt="地图" src="/images/assets-map.jpg" className="block-img" />
          </div>

          <div className="center-title grey-bg">
            以及 100+功能，持续更新升级
              <span className="subtitle">强大、精致，比肩系统原生APP</span>
          </div>
          <div className="action-img action-center-bottom">
            <img alt="功能" src="/images/assets-function.jpg" className="block-img" />
          </div>
          <Collapse bordered={false}>
            <Panel header="更多功能清单" key="1">
              <ul className="ul">
                <li>汇总多个公众号的内容</li>
                <li>无缝接入企业微信</li>
                <li>精确到每一个用户的数据分析</li>
                <li>可以直接拨打电话的商务洽谈</li>
                <li>可以直接发给客户的项目搜索</li>
                <li>按距离展示项目</li>
                <li>图片+短视频直播</li>
                <li>团队独立页面</li>
                <li>ins风格项目照片墙</li>
                <li>自定义项目分享海报</li>
                <li>项目评论、点赞</li>
                <li>点对点推送</li>
                <li>微信客服在线应答</li>
                <li>持续更新中……</li>
              </ul>
            </Panel>
          </Collapse>

          <div className="center-title black-bg">
            可视化大数据<br />让企业真正拥有自己的用户
              <span className="subtitle"></span>
            <span className="subtitle">精准还原浏览轨迹，让每一次曝光更有价值</span>

          </div>
          <div className="action-img action-right-center">
            <Anchor affix={false}>
              <Link href="#apply-pro" title='申请体验' className="action-btn" />
            </Anchor>
            <img alt="大数据" src="/images/assets-data.jpg" className="block-img" />
          </div>

          <div className="center-title black-bg">
            简单且强大的管理后台
              <span className="subtitle">拖拽操作，5分钟上手</span>
          </div>

          <div className="action-img action-center-bottom">
            <img alt="后台" src="/images/assets-system.jpg" className="block-img" />
          </div>

          <div className="center-title black-bg">
            零技术负担，开箱即用<br />一小时上线，价值持续不断
              <span className="subtitle">无需关心服务器、带宽等细节</span>
          </div>
          <div className="action-img action-center-bottom">
            <div className="healthy-dot">
              <span></span>
                顶尖的企业级服务保障
              </div>
            <Anchor affix={false}>
              <Link href="#apply-pro" title='申请体验' className="action-btn" />
            </Anchor>
            <img alt="云服务" src="/images/assets-cloud.jpg" className="block-img" />
          </div>



          <div className="small-block black-bg">ps.<br /><br />
            倍受行业用户喜爱的新一代平台<br />不带Pro的园也，全流量导入支持</div>

          <div className="block">
            <div className="block-title">简单三步，获得企业专属小程序</div>
            <ol className="ol">
              <li>使用现有公众号直接开通小程序</li>
              <li>在园也Pro的管理后台录入项目等内容</li>
              <li>签约，发布上线</li>
            </ol>
          </div>

          <div id="apply-pro">
            <div className="large-title" id="apply-pro">我也要使用园也<span className="pro">Pro</span> </div>
            <img alt="二维码" src="/images/assets-qrcode.jpg" style={{ width: '215px', margin: '20px auto 40px', display: 'block' }} />
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default Pro;