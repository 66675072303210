import React, {
  Component
} from 'react';
import DocumentTitle from 'react-document-title'
import "./project.css"
import "./company-127.css"

class Company127 extends Component {

  state = {
    designer: [],
    projectQRCode: "",
    projectName: ""
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    this.getProject()
  }

  getProject() {
    fetch('/api/website/project/' + this.props.match.params.projectId, {
      method: 'GET',
    }).then(async res => {
      if (res.status === 200) {
        const project = await res.json();
        if (!project.errorKey) {
          this.getWxaCode()
        }
        this.setState({
          projectName: project.name,
          projectType: project.type.join(" #"),
          designer: project.designers[0],
        })
      } else {
        this.props.history.replace("/project-not-found")
      }
    });
  }

  getWxaCode() {
    fetch('/api/weixin/getWxaCode/project?designerId=127&projectId='
      + this.props.match.params.projectId +
      '&source1st=' + decodeURIComponent(this.props.match.params.source1st) + 
      '&sourceFrom=' + this.props.match.params.sourceFrom, {
      method: 'GET',
      headers: {
        "Authorization": localStorage.getItem('token'),
      },
    }).then(async res => {
      const blob_1 = await res.blob();
      var reader_1 = new FileReader();
      reader_1.readAsDataURL(blob_1);
      reader_1.onloadend = () => {
        this.setState({
          projectQRCode: reader_1.result
        });
      };
    })
  }

  render() {

    const { projectName } = this.state

    return (
      <DocumentTitle title={projectName}>
        <div className={"company-127 " + this.props.match.params.mode}>
          <div className="cover-bg">
            <div className="poster-mask">
              <div className="project-content">
                <div className={projectName.length > 12 ? 'project-title small-font' : 'project-title'}>{projectName}</div>
                <div className="project-summary">{decodeURIComponent(this.props.match.params.comment)}</div>
              </div>
              <div className="poster-qrcode" >
                <img src={this.state.projectQRCode} className="qrcode" alt="qrcode" />
                <div className="poster-qrcode-hint">扫一扫查看详情</div>
              </div>
            </div>
          </div>
          <style>
            {`.cover-bg {
              background-image: url(` + decodeURIComponent(this.props.match.params.cover) + `-web.project.cover.high);
            }`}
          </style>
        </div>
      </DocumentTitle>
    )
  }
}

export default Company127;