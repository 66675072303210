import React, { Component } from 'react';
import "./card.css"

class Card extends Component {

  state = {
    card: {},
    projectQRCode: ""
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    this.getCard()
  }

  getCard() {
    fetch('/api/designer/contacts/card-alt?contactsId=' + this.props.match.params.contactsId, {
      method: 'GET',
      headers: {
        "Authorization": decodeURIComponent(this.props.match.params.token)
      }
    }).then(async res => {
      if (res.status === 200) {
        const card = await res.json()
        this.getWxaCode(card.id, card.designerId)
        if (card.firstName !== '') {
          card.contactsName = card.lastName + card.firstName
        }
        this.setState({
          card
        })
      } else {
        this.props.history.replace("/project-not-found")
      }
    });
  }

  getWxaCode(contacsId, designerId) {
    fetch('/api/weixin/getWxaCode/card?contactsId=' + contacsId + '&designerId=' + designerId +
      '&sourceFrom=' + this.props.match.params.sourceFrom +
      '&source1st=' + decodeURIComponent(this.props.match.params.source1st), {
      method: 'GET',
      headers: {
        "Authorization": localStorage.getItem('token'),
      },
    }).then(async res => {
      const blob_1 = await res.blob();
      var reader_1 = new FileReader();
      reader_1.readAsDataURL(blob_1);
      reader_1.onloadend = () => {
        this.setState({
          projectQRCode: reader_1.result
        });
      };
    })
  }

  render() {

    const { card } = this.state

    return (
      <div className="card-wrapper" style={{ color: card.cardFrontColor, backgroundColor: card.cardBgColor }}>
        <div className="card-mask" style={{ backgroundImage: "url(" + card.cardBg + "-gif.support)" }}></div>
        <div className="content-content">
          <div className="card-name" style={{ color: card.cardNameColor }}>{card.contactsName}</div>
          <div className="card-title">
            {card.department !== '' ?
              <div>{card.department}</div>
              : null}
            <pre>{card.title}</pre>
          </div>
          {card.professionalTitle !== '' ?
            <div className="card-title-alt">
              <pre>{card.professionalTitle}</pre>
            </div>
            : null}
        </div>
        <div className="card-qrcode" >
          <img src={this.state.projectQRCode} className="qrcode" alt="qrcode" />
        </div>
        <div className="card-card">
          <div className="card-company">{card.cardCompanyBranchName ? card.organization : card.designerFullName}</div>
          {card.stockCode !== '' ?
            <div className="card-other">股票代码：{card.stockCode}</div>
            : null}
          <div className="card-other">{card.url}</div>
          <div className="card-other">{card.addressStreet}</div>
          <div className="card-other">
            <div className="btn-icon icon-phone" style={{ backgroundColor: card.cardFrontColor }}></div>
            {card.contactsNumber}
            {card.email !== '' ?
              <div className="btn-icon icon-email" style={{ backgroundColor: card.cardFrontColor }}></div>
              : null}
            {card.email !== '' ?
              card.email
              : null}
          </div>
        </div>
      </div>
    )
  }
}

export default Card;