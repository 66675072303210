import React from 'react';
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page kw1whz49kqq-editor_css' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 kw1whzvz3f-editor_css' },
        title: {
          className: 'banner2-title',
          children: 'https://cdn.yuanyeapp.com/0-pro-0002.png',
        },
        content: {
          className: 'banner2-content kw1wjpurjqn-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    &nbsp; <span>数字化营销一站式解决方案</span>
                  </p>
                </span>
              </span>
            </span>
          ),
        },
        button: {
          className: 'banner2-button kw1wj2bc87-editor_css',
          children: 'Learn More',
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>是官网，也是旗舰店</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://cdn.yuanyeapp.com/0-pro-0007.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>人手一张电子名片</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>客户所需“打包”发送</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://cdn.yuanyeapp.com/0-pro-0008.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>业内独家项目地图</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>按省、市自动汇总项目</p>
                    <p>以及完全自定义的地图</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://cdn.yuanyeapp.com/0-pro-0009.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>量化营销细化运营</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>精细化运营，增加留存，提高转化率</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: 'https://cdn.yuanyeapp.com/0-pro-0005.jpg' },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>企业数字资产管理</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>集内部管理与对外宣传于一体的数字资产管理平台</p>
        <p>国内唯一，桌面/移动全覆盖。</p>
      </span>
    ),
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper kw1wukvvkal-editor_css',
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>零技术负担，开箱即用，半小时上线</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>无需关心服务器、带宽等细节</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://cdn.yuanyeapp.com/0-pro-0012.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>功能持续更新保证</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>有需求随时提</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block kw1xdwwknrk-editor_css',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://cdn.yuanyeapp.com/0-pro-0011.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>
                    一价全包，按需增值
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>无任何隐性支出</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://cdn.yuanyeapp.com/0-pro-0010.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>顶尖的技术保障</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>国内顶尖云服务，宕机就敢赔</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper kw1xn0ht4xi-editor_css',
  },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>我也要用园也Pro</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper kw1xifh04bo-editor_css',
    children: [
      {
        name: 'block1',
        className: 'block1',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: 'https://www.yuanyeapp.com/images/assets-qrcode.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>扫码申请体验</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>
          <span>©2021 园也Pro&nbsp;&nbsp;All Rights Reserved</span>
        </p>
      </span>
    ),
  },
};
