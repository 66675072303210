import React, {
  Component
} from 'react';
import { Avatar, Row, Col } from 'antd';
import DocumentTitle from 'react-document-title'
import "./project.css"
import "./company-project.css"

class CompanyProject extends Component {

  state = {
    hasDesigner: false,
    designer: [],
    projectQRCode: "",
    projectName: ""
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    this.getProject()
  }

  getProject() {
    fetch('/api/website/project/' + this.props.match.params.projectId, {
      method: 'GET',
    }).then(async res => {
      if (res.status === 200) {
        const project = await res.json();
        if (!project.errorKey) {
          this.getWxaCode()
        }
        this.setState({
          projectName: project.name,
          projectType: project.type.join(" #"),
          designer: project.designers[0],
          hasDesigner: true
        })
      } else {
        this.props.history.replace("/project-not-found")
      }
    });
  }

  getWxaCode() {
    fetch('/api/weixin/getWxaCode/project?designerId=' + this.props.match.params.companyId + 
    '&projectId=' + this.props.match.params.projectId + 
    '&source1st=' + this.props.match.params.source1st +
    '&sourceFrom=' + this.props.match.params.sourceFrom, {
      method: 'GET',
      headers: {
        "Authorization": localStorage.getItem('token'),
      },
    }).then(async res => {
      const blob_1 = await res.blob();
      var reader_1 = new FileReader();
      reader_1.readAsDataURL(blob_1);
      reader_1.onloadend = () => {
        this.setState({
          projectQRCode: reader_1.result
        });
      };
    })
  }

  render() {

    const d = this.state.designer

    return (
      <DocumentTitle title={this.state.projectName}>
        <div className="company-project">
          <div className="wrapper">

            <div className="project-banner-area">
              <div className="project-title-container">
                <h4 className="project-company-title"><Avatar shape="circle" size="small" alt={d.name} src={d.logo + "-designer.logo"} /> {d.name}</h4>
                <h3 className="project-title">{this.state.projectName}</h3>
              </div>
            </div>
            <style>
              {`
                  .project-banner-area {
                    background-image: url(` + decodeURIComponent(this.props.match.params.cover) + `-web.project.cover);
                  }
                `}
            </style>
            <div className="project-footer-area">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} >
                  <div className="footer-comment">
                    <h4><Avatar shape="circle" src={decodeURIComponent(this.props.match.params.avatarUrl)} /> {decodeURIComponent(this.props.match.params.nickName)} 的推荐</h4>
                    <p className="project-summary">{decodeURIComponent(this.props.match.params.comment)}</p>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                  <div className="footer-qrcode" >
                    <Avatar shape="square" size={129} src={this.state.projectQRCode} className="avatar-qrcode" />
                    <div className="footer-qrcode-hint">
                      长按识别二维码<br />查看详情
                    </div>
                  </div>
                </Col>
              </Row>

            </div>
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default CompanyProject;