import React, {
	Component
} from 'react';
import {
	Avatar,
	Card,
	Row,
	Col
} from 'antd';
import DocumentTitle from 'react-document-title'
import "./index.css"

const {
	Meta
} = Card;

class Index extends Component {

	state = {
		
	}

	componentWillMount() {
		window.scrollTo(0, 0)
	}

	componentDidMount() {
	}


	render() {

		return (
			<DocumentTitle title="园也 - 发现最好的景观">
			<div className="wrapper">
			<div className={"logo-area"}>
				<Card className="company-card" hoverable={false} bordered={false}
                  cover={<Avatar shape='square' size={129} src={"https://cdn.yuanyeapp.com/qrcode_215.jpg"} />} >
                    <Meta description='发现最好的景观' />
                </Card>
			</div>
			
			<Row className={"company-list-area"}>
				<Col xs={0} sm={0} md={1} lg={2} xl={3} ></Col>
				<Col xs={24} sm={24} md={22} lg={20} xl={18} className={"company-list"} >
					<p style={{textAlign: 'center'}}>©园也 <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">京ICP备12044039号</a></p>
				</Col>
				<Col xs={0} sm={0} md={1} lg={2} xl={3} ></Col>
			</Row>
			</div>
			</DocumentTitle>
		)
	}
}

export default Index;