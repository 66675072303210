import React, { Component } from 'react';
import { Avatar, Row, Col } from 'antd';
import "./project.css"
import "./company-project.css"

class CollectionPoster extends Component {

  state = {
    collectionDetail: [],
    collectionProjects: [],
    collectionQRCode: "",
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    this.getCollection()
  }

  getCollection() {
    fetch('/api/website/collection/' + this.props.match.params.collectionId, {
      method: 'GET',
    }).then(async res => {
      if (res.status === 200) {
        const res_1 = await res.json();
        this.getWxaCode()
        this.setState({
          collectionDetail: res_1,
          collectionProjects: res_1.projects
        })
        console.log(res_1)
      }
    });
  }

  getWxaCode() {
    fetch('/api/weixin/getWxaCode/collection?collectionId=' + this.props.match.params.collectionId, {
      method: 'GET',
      headers: {
        "Authorization": localStorage.getItem('token'),
      },
    }).then(async res => {
      const blob_1 = await res.blob();
      var reader_1 = new FileReader();
      reader_1.readAsDataURL(blob_1);
      reader_1.onloadend = () => {
        this.setState({
          collectionQRCode: reader_1.result
        });
      };
    })
  }

  render() {

    const c = this.state.collectionDetail
    const p = this.state.collectionProjects

    return (
      <div className="collection-poster">
        <div className="wrapper">

          <div className={"project-banner-area banner-" + this.props.match.params.mode}>
            
          </div>
          <style>
            {`
                  .project-banner-area {
                    background-image: url(` + decodeURIComponent(this.props.match.params.cover) + `-web.project.cover);
                  }
                `}
          </style>
          <div className="project-footer-area">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} >
                  <div className={"footer-comment footer-" + this.props.match.params.mode}>
                    <h5>园也地图</h5>
                    <h3 className="project-title">{c.title}</h3>
                <h4><Avatar shape="circle" src={decodeURIComponent(c.authorAvatar)} /> {decodeURIComponent(c.authorName)} 创建，共{p.length}个项目</h4>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                  <div className="footer-qrcode" >
                    <Avatar shape="square" size={129} src={this.state.collectionQRCode} className="avatar-qrcode" />
                    <div className="footer-qrcode-hint">
                      长按识别二维码<br />查看详情
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
        </div>
      </div>
    )
  }
}

export default CollectionPoster;